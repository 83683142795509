import Link from "next/link";
import styles from "../../navigation.module.scss";
import { NavigationComponentProps } from "../../props";
import {
  extractRegionFromCurrentSlug,
  vehicleLinkBuilderByReference,
} from "~/utils";
import { WebsitePreferenceType } from "~/components/editable/inventory";
import { toTitleCase } from "@sync/utils/src/platforms";

export const NavigationVariant01 = (props: NavigationComponentProps) => {
  const site = props.data.site;

  const region = extractRegionFromCurrentSlug(props.data.page?.currentSlug);

  const preferences = props.data.site?.preferences ?? [];

  const regionPreferences = preferences.filter(
    (pref) => pref.type === WebsitePreferenceType.localSeoSlug
  );

  const regionTopPreference =
    regionPreferences?.[0]?.value.split(",")[0] ?? "brasil";

  const regions = regionPreferences.map((item) => item.value).join(",");

  let regionsArr = toTitleCase(regions).split(",");

  regionsArr = regionsArr.filter((region) => region.trim() !== "");

  const isBrandNewInventory = props.data.vehicleList.every(
    (i) => i.vehicleZeroKMOutput
  );
  const isPreOwnedInventory = props.data.vehicleList.every(
    (i) => !i.vehicleZeroKMOutput
  );

  const inventoryText =
    isBrandNewInventory && !isPreOwnedInventory
      ? "Veículos novos em"
      : !isBrandNewInventory && isPreOwnedInventory
      ? "Seminovos em"
      : "Veículos novos e seminovos em";

  const categoriesInStock = new Set(
    (props.data.vehicleList ?? [])
      .map((vehicle) => vehicle.category)
      .filter((category) => category != null && category !== "0")
  );

  const motorcycleStylesInStock = new Set(
    (props.data.vehicleList ?? [])
      .map((vehicle) => vehicle.style)
      .filter((style) => style != null)
  );

  const modelsInStock = new Set(
    (props.data.vehicleList ?? [])
      .map((vehicle) => vehicle.model)
      .filter((model) => model != null)
  );

  const makesInStock = new Set(
    (props.data.vehicleList ?? [])
      .map((vehicle) => vehicle.make)
      .filter((make) => make != null)
  );

  const yearsInStock = new Set(
    (props.data.vehicleList ?? [])
      .map((vehicle) => vehicle.modelYear)
      .filter((year) => year != null)
  );

  const availableCategories = [
    "Hatch",
    "Hatchback",
    "Sedan",
    "Sedã",
    "Utilitário esportivo",
    "Picape",
    "SUV",
    "Monovolume",
    "Wagon/Perua",
    "Van",
    "Van/Utilitário",
    "Conversível/Cupê",
    "Hibrido/Elétrico",
  ];

  const ordenedYears = Array.from(yearsInStock).sort((a, b) => a - b);

  return (
    <section className={`${styles.section}`}>
      <div className={`${styles.container}`}>
        <input
          type="checkbox"
          id="toggleAll"
          className={styles.toggleCheckbox}
        />

        <div className={`${styles.content__wrapper} ${styles.limitedHeight}`}>
          {makesInStock.size > 0 && (
            <div className={`${styles.column}`}>
              <h2>Marcas</h2>
              <ul className={`${styles.list}`}>
                {Array.from(makesInStock).map((make, index) => {
                  const currentLink =
                    vehicleLinkBuilderByReference({
                      ref: "make",
                      value: make,
                      site: {
                        subdomain: site?.subdomain as string,
                        customDomain: site?.customDomain ?? "",
                        preferences: site?.preferences ?? [],
                      },
                      vehicleList: props.data.vehicleList,
                      region,
                    }) ?? "/";

                  return (
                    <li
                      className={`${styles.list__item} list__item-${props.id}`}
                      key={`list-item-${make}-${index}`}
                    >
                      <Link
                        className={`link__item-${props.id}`}
                        href={currentLink}
                        prefetch={false}
                      >
                        Comprar {make}{" "}
                        {region
                          ? `em ${region}`
                          : `em ${toTitleCase(
                              regionTopPreference ?? "brasil"
                            )}`}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {modelsInStock.size > 0 && (
            <div className={`${styles.column}`}>
              <h2>Modelos</h2>
              <ul className={`${styles.list}`}>
                {Array.from(modelsInStock).map((model, index) => {
                  const currentLink =
                    vehicleLinkBuilderByReference({
                      ref: "model",
                      value: model,
                      site: {
                        subdomain: site?.subdomain as string,
                        customDomain: site?.customDomain ?? "",
                        preferences: site?.preferences ?? [],
                      },
                      vehicleList: props.data.vehicleList,
                      region,
                    }) ?? "/";

                  return (
                    <li
                      className={`${styles.list__item} list__item-${props.id}`}
                      key={`list-item-${model}-${index}`}
                    >
                      <Link
                        className={`link__item-${props.id}`}
                        href={currentLink}
                        prefetch={false}
                      >
                        Comprar {model}{" "}
                        {region
                          ? `em ${region}`
                          : `em ${toTitleCase(
                              regionTopPreference ?? "brasil"
                            )}`}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {categoriesInStock.size > 0 && (
            <div className={`${styles.column}`}>
              <h2>Categorias</h2>
              <ul className={`${styles.list}`}>
                {Array.from(categoriesInStock).map((category, index) => {
                  if (
                    !availableCategories.some(
                      (i) => i.toLowerCase() === category.toLowerCase()
                    )
                  ) {
                    return;
                  }

                  const currentLink =
                    vehicleLinkBuilderByReference({
                      ref: "category",
                      value: category,
                      site: {
                        subdomain: site?.subdomain as string,
                        customDomain: site?.customDomain ?? "",
                        preferences: site?.preferences ?? [],
                      },
                      vehicleList: props.data.vehicleList,
                      region,
                    }) ?? "/";

                  return (
                    <li
                      className={`${styles.list__item} list__item-${props.id}`}
                      key={`list-item-${category}-${index}`}
                    >
                      <Link
                        className={`link__item-${props.id}`}
                        href={currentLink}
                        prefetch={false}
                      >
                        Carros {category}{" "}
                        {region
                          ? `em ${region}`
                          : `em ${toTitleCase(
                              regionTopPreference ?? "brasil"
                            )}`}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {motorcycleStylesInStock.size > 0 &&
            Array.from(motorcycleStylesInStock).some((i) => i) && (
              <div className={`${styles.column}`}>
                <h2>Estilos de Motos</h2>
                <ul className={`${styles.list}`}>
                  {Array.from(motorcycleStylesInStock).map((style, index) => {
                    if (!style) return;

                    const currentLink =
                      vehicleLinkBuilderByReference({
                        ref: "style",
                        value: style as string,
                        site: {
                          subdomain: site?.subdomain as string,
                          customDomain: site?.customDomain ?? "",
                          preferences: site?.preferences ?? [],
                        },
                        vehicleList: props.data.vehicleList,
                        region,
                      }) ?? "/";

                    return (
                      <li
                        className={`${styles.list__item} list__item-${props.id}`}
                        key={`list-item-${style}-${index}`}
                      >
                        <Link
                          className={`link__item-${props.id}`}
                          href={currentLink}
                          prefetch={false}
                        >
                          Motos {style}{" "}
                          {region
                            ? `em ${region}`
                            : `em ${toTitleCase(
                                regionTopPreference ?? "brasil"
                              )}`}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

          {ordenedYears.length > 0 && (
            <div className={`${styles.column}`}>
              <h2>Ano do modelo</h2>
              <ul className={`${styles.list}`}>
                {ordenedYears.map((year, index) => {
                  const currentLink =
                    vehicleLinkBuilderByReference({
                      ref: "year",
                      value: year.toString(),
                      site: {
                        subdomain: site?.subdomain as string,
                        customDomain: site?.customDomain ?? "",
                        preferences: site?.preferences ?? [],
                      },
                      vehicleList: props.data.vehicleList,
                      region,
                    }) ?? "/";

                  return (
                    <li
                      className={`${styles.list__item} list__item-${props.id}`}
                      key={`list-item-${year}-${index}`}
                    >
                      <Link
                        className={`link__item-${props.id}`}
                        href={currentLink}
                        prefetch={false}
                      >
                        Veículos {year}{" "}
                        {region
                          ? `em ${region}`
                          : `em ${toTitleCase(
                              regionTopPreference ?? "brasil"
                            )}`}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {regionsArr.length > 0 && (
            <div className={`${styles.column}`}>
              <h2>Regiões</h2>
              <ul className={`${styles.list}`}>
                {regionsArr.map((reg, index) => {
                  const currentLink =
                    vehicleLinkBuilderByReference({
                      ref: "region",
                      value: reg,
                      site: {
                        subdomain: site?.subdomain as string,
                        customDomain: site?.customDomain ?? "",
                        preferences: site?.preferences ?? [],
                      },
                      vehicleList: props.data.vehicleList,
                      region,
                    }) ?? "/";

                  return (
                    <li
                      className={`${styles.list__item} list__item-${props.id}`}
                      key={`list-item-${reg}-${index}`}
                    >
                      <Link
                        className={`link__item-${props.id}`}
                        href={currentLink}
                        prefetch={false}
                      >
                        {inventoryText} {reg}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>

        <div className="!w-full flex justify-center items-center text-center">
          <label className={styles.showMoreButton} htmlFor="toggleAll">
            Ver Mais
          </label>
        </div>
      </div>
    </section>
  );
};
