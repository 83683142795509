"use client";

import { useEffect, useRef, useState } from "react";
import { useAnimation, AnimationControls } from "framer-motion";
import VehicleGallery from "~/components/non-editable/misc/vehicle-gallery";
import { VehicleGalleryVariant } from "~/components/non-editable/misc/vehicle-gallery/props";
import { DetailsVariantsProps } from "../../../props";
import useDeviceType from "~/hooks/useDeviceType";
import {
  DoorClosed,
  Fuel,
  SlidersVertical,
  FileSpreadsheet,
  ArrowRight,
} from "lucide-react";
import { DetailsTabs } from "./tabs";
import Link from "next/link";
import "../../../style.css";
import { FloatHeader } from "./float-header";
import FeatureSlider from "./features-slider";
import { CustomHyundaiStyles } from "./custom-styles";
import { HyundayHero } from "./hero";
import { Downloads } from "./downloads";
import { ModalForm } from "./form";

export function BrandNewVehicleDetailsVariant1({
  props,
  vehicle,
}: DetailsVariantsProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(false);
  const { isMobile } = useDeviceType();
  const detailsRef = useRef<HTMLDivElement>(null);
  const controls: AnimationControls = useAnimation();

  const externalImages = vehicle.images.split(",");

  const features = vehicle.vehicleZeroKMOutput?.files
    .filter((file) => file.category === 4 && file.title && file.description)
    .sort((a, b) => a.position - b.position);

  const files = vehicle.vehicleZeroKMOutput?.files
    .filter((file) => file.category === 3 && file.title)
    .sort((a, b) => a.position - b.position);

  const manufactureYear =
    vehicle.vehicleZeroKMOutput?.vehicleSpecification.vehicleCategories
      .find((i) => i.name === "Dados básicos")
      ?.vehicleItems.find((i) => i.name === "Ano de fabricação")?.value ?? null;

  const modelYear =
    vehicle.vehicleZeroKMOutput?.vehicleSpecification.modelYear ?? null;

  const vehicleColors = vehicle.vehicleZeroKMOutput?.colors ?? [];

  const vehicleSpecification = [
    {
      label: "doors",
      value: vehicle.doors,
    },
    { label: "fuelType", value: vehicle.fuel },
    {
      label: "transmissionType",
      value: vehicle.transmission,
    },
  ];

  const handleIcon = (key: string) => {
    switch (key) {
      case "doors":
        return <DoorClosed color="black" size={18} strokeWidth={1.5} />;
      case "fuelType":
        return <Fuel color="black" size={18} strokeWidth={1.5} />;
      case "transmissionType":
        return <SlidersVertical color="black" size={18} strokeWidth={1.5} />;
    }
  };

  const horsePower =
    vehicle.vehicleZeroKMOutput?.vehicleSpecification?.vehicleCategories
      ?.find((i) => i.name === "Motor / Desempenho / Consumo")
      ?.vehicleItems?.find((x) => x.name === "Potência (cv/rpm)")
      ?.value?.split("/")[0] ?? null;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsHeaderVisible(!entry.isIntersecting);
      },
      { threshold: 0 }
    );

    if (detailsRef.current) {
      observer.observe(detailsRef.current);
    }

    return () => {
      if (detailsRef.current) {
        observer.unobserve(detailsRef.current);
      }
    };
  }, [detailsRef]);

  useEffect(() => {
    if (isHeaderVisible) {
      controls.start({ y: 0, opacity: 1 });
    } else {
      controls.start({ y: -100, opacity: 0 });
    }
  }, [isHeaderVisible, controls]);

  return (
    <>
      <CustomHyundaiStyles />
      <main id="main" className="mt-6">
        <div
          className={`flex ${
            isMobile ? "flex-wrap" : ""
          } gap-3 !px-3 lg:px-0 max-w-[1500px] mx-auto mb-5`}
        >
          {vehicle &&
            props.options != undefined &&
            props.options.galeryType != undefined && (
              <VehicleGallery
                variant={
                  String(
                    props.options.galeryType?.value ?? props.options.galeryType
                  ) as VehicleGalleryVariant
                }
                vehicle={vehicle}
              />
            )}
          <div
            id="details"
            ref={detailsRef}
            className="md:min-w-[500px] md:max-w-[550px] w-full flex-1 mt-6 py-4 rounded-lg mx-auto relative"
          >
            <h1 className="font-semibold text-xl">
              {vehicle.make} - {vehicle.model}{" "}
              {manufactureYear ? `${manufactureYear}/${modelYear}` : ""}
            </h1>
            <h2 className="text-xl md:text-sm">
              {vehicle.version} -{" "}
              {vehicle.vehicleZeroKMOutput?.vehicleSpecification.category}
            </h2>

            <div className="mt-5">
              <Link
                href="#technical-sheet"
                scroll={true}
                onClick={(e) => {
                  e.preventDefault();
                  const element = document.getElementById("technical-sheet");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                className="text-lg md:text-sm text-gray-500 flex gap-2"
              >
                <FileSpreadsheet color="#6B7280" strokeWidth={1.5} />
                <span className="border-b-[1px] border-gray-500">
                  Ficha Técnica
                </span>
              </Link>
            </div>

            <div className="mt-5">
              <h3 className="font-semibold text-lg md:text-sm">Informações:</h3>

              <div className="flex flex-wrap gap-2 mt-2">
                {vehicleSpecification.map((i, index) => (
                  <div
                    key={`${i.label + "-" + i.value + index}`}
                    className="bg-[#DFE4E8] w-fit gap-2 px-2 py-1 flex items-center justify-center flex-nowrap rounded-lg"
                  >
                    {handleIcon(i.label)}
                    <span className="text-[12px] whitespace-nowrap">
                      {i.label === "doors" ? `${i.value} portas` : i.value}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="border-b-[1px] border-gray-200 h-[1px] w-full my-[20px] rounded-full" />

            <div className="flex justify-between items-center">
              <p className="text-lg md:text-sm">Preço</p>
              <p className="font-semibold text-lg md:text-sm">
                {Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(vehicle.price)}
              </p>
            </div>

            <div className="border-b-[1px] border-gray-200 w-full my-[20px] rounded-full" />

            {horsePower && (
              <>
                <div className="text-lg md:text-sm flex justify-between items-center">
                  <p>Potência</p>
                  <p className="font-semibold">{horsePower}cv</p>
                </div>

                <div className="border-b-[1px] border-gray-200 w-full my-[20px] rounded-full" />
              </>
            )}

            <div>
              <h4 className="font-semibold text-lg md:text-sm">
                Cores Disponíveis:
              </h4>
              <div className="flex gap-4 mt-2 flex-wrap">
                {vehicleColors.map((color, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-end min-w-[55px] min-h-[55px] w-12 h-12 p-1 rounded-lg shadow-md cursor-pointer"
                    style={{ backgroundColor: color.hexaCode }}
                  >
                    <span
                      className="text-[10px] flex justify-end items-end font-medium text-white"
                      style={{ textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)" }}
                    >
                      {color.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-20 md:mt-2 lg:!mt-[38%]">
              <button
                onClick={() => setOpen(true)}
                className="px-6 py-3 w-full bg-primary-800 text-white font-bold rounded-lg shadow-md hover:bg-primary-700 transition-colors flex items-center justify-center"
              >
                Solicitar Cotação <ArrowRight className="ml-2" />
              </button>
            </div>
          </div>
        </div>

        {features && features.length > 0 && (
          <FeatureSlider vehicle={vehicle} features={features} />
        )}

        <HyundayHero
          setOpen={setOpen}
          externalImages={externalImages}
          vehicle={vehicle}
        />

        <DetailsTabs vehicle={vehicle} />

        {files && files.length > 0 && <Downloads files={files} />}
      </main>

      <ModalForm
        open={open}
        props={props}
        setOpen={setOpen}
        vehicle={vehicle}
      />

      {isHeaderVisible && <FloatHeader controls={controls} setOpen={setOpen} />}
    </>
  );
}
