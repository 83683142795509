"use client";

import Link from "next/link";

import styles from "./card-variant-03.module.scss";
import { PlatformsImage } from "~/components/non-editable/core";
import { VehicleCardVariantProps } from "../props";
import Chip from "~/components/non-editable/misc/chip";
import { useGetLocalUrl } from "~/hooks/useGetOriginHref";
import { Share2 } from "lucide-react";
import { ModalForm } from "../../../details-1/variants/brand-new/01/form";
import { useState } from "react";

export default function VehicleCardVariant03({
  vehicle,
  priority,
  options,
  adjustHeightBasedOnOptions,
  imageThumb,
  vehiclePrice,
  formatedSlug,
  id,
  props,
}: VehicleCardVariantProps) {
  const { localUrl } = useGetLocalUrl();

  const [open, setOpen] = useState<boolean>(false);

  const features = vehicle.vehicleZeroKMOutput?.files
    .filter((file) => file.category === 4 && file.title && file.description)
    .map((i) => i.title)
    .join(",");

  async function handleShare() {
    await navigator.share({ url: localUrl });
  }

  return (
    <>
      <Link
        href={formatedSlug}
        className={`${styles.card} ${
          adjustHeightBasedOnOptions ? styles["card--adjust-height"] : ""
        }`}
        prefetch
        onClick={(event) => {
          if (
            (event.target as HTMLElement).closest(".share-button") ||
            (event.target as HTMLElement).closest(".contact-button")
          ) {
            event.preventDefault();
          }
        }}
      >
        <PlatformsImage
          width={500}
          height={500}
          layout="responsive"
          src={imageThumb}
          alt={`Imagem principal do veículo`}
          className={`${styles.image} image-${id}`}
          priority={priority}
          loading={priority ? "eager" : "lazy"}
        />
        <div className={`${styles.content} content-${id} relative py-4 px-4`}>
          <div className="mb-3">
            <h3 className="-mt-1">
              {(vehicle?.type ?? "")?.toLowerCase() === "car" ? (
                <>
                  {vehicle.make}{" "}
                  <span className={`${styles.highlight} highlight-${id}`}>
                    {vehicle.model}
                  </span>
                </>
              ) : (
                <span>{vehicle.make}</span>
              )}
            </h3>
            <p
              className={`${styles.description} description-${id} -mt-1 !text-[16px]`}
            >
              {(vehicle?.type ?? "")?.toLowerCase() === "car"
                ? vehicle.version
                : vehicle.model}
            </p>
          </div>
          <div className={`${styles["text-wrapper"]} text-wrapper-${id}`}>
            <button
              className={`share-button border-none bg-transparent rounded-full hover:bg-white/45 pl-[2px] pt-[3px] pr-[4.5px] pb-[3px] absolute top-2 md:top-4 right-2 md:right-4 z-50 flex justify-center items-center transition-colors`}
              onClick={handleShare}
            >
              <Share2 strokeWidth={1} />
            </button>
            <div className={`${styles["chip_row"]} chip_row-${id} mb-3`}>
              {(vehicle?.features || features)
                ?.split(",")
                ?.filter((_, idx) => idx < 5)
                .map((feat) => (
                  <Chip textOnly>• {feat}</Chip>
                ))}
            </div>
            {vehiclePrice &&
              Boolean(
                options.showVehiclePrice?.value ?? options.showVehiclePrice
              ) && (
                <div
                  className={`${styles["text-wrapper"]}} text-wrapper-${id}`}
                >
                  <h4>
                    <span className={`${styles.price} price-${id}`}>R$</span>{" "}
                    {vehiclePrice}
                  </h4>
                </div>
              )}

            <div className={`${styles.row} row-${id}`}>
              <div className="flex">
                {Boolean(
                  options.showYearFilter?.value ?? options.showYearFilter
                ) && (
                  <p>
                    {" "}
                    {vehicle.vehicleZeroKMOutput
                      ? ""
                      : `${vehicle.manufactureYear}/`}
                    {vehicle.modelYear}
                  </p>
                )}
                <p className="mx-2">-</p>
                {Boolean(
                  options.showKmFilter?.value ?? options.showKmFilter
                ) && (
                  <p>
                    {" "}
                    {Intl.NumberFormat("pt-BR", {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }).format(Number(vehicle.kilometers))}{" "}
                    km
                  </p>
                )}
              </div>
              <div className="!w-full flex justify-end items-center">
                <button
                  type="button"
                  onClick={() => setOpen(true)}
                  className="contact-button bg-primary-800 rounded-lg px-2 py-1 text-white text-sm font-semibold"
                >
                  Falar agora
                </button>
              </div>
            </div>

            {Boolean(
              options.showVehiclePrice?.value ?? options.showVehiclePrice
            ) === false &&
              String(
                options.textAboveVehiclePrice?.value ??
                  options.textAboveVehiclePrice
              ) !== "" &&
              options.textAboveVehiclePrice !== undefined && (
                <div
                  className={`${styles["text-wrapper"]}} text-wrapper-${id}`}
                >
                  <h4>
                    {String(
                      options.textAboveVehiclePrice?.value ??
                        options.textAboveVehiclePrice
                    )}
                  </h4>
                </div>
              )}
          </div>
        </div>
      </Link>
      {props && (
        <ModalForm
          open={open}
          props={props}
          setOpen={setOpen}
          vehicle={vehicle}
        />
      )}
    </>
  );
}
