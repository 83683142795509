import Link from "next/link";
import { ArrowLeft } from "lucide-react";

export const BackButton = () => (
  <div className="w-full px-3 pt-10">
    <div>
      <Link
        href="/"
        className="flex gap-4 items-center w-fit hover:underline pr-2"
      >
        <ArrowLeft />
        <span className="font-semibold text-lg">Voltar</span>
      </Link>
    </div>
  </div>
);
